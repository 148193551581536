
@import '~antd/dist/antd.css';

.App {
  text-align: left;
}
.AppLogin{
  vertical-align: middle;
  margin-top:12%;
}
.App-header {
  background-color: #222;
  height: 25px;
  padding: 20px;
  color: white;
  margin-bottom: 2em;
}

.App-title {
  font-size: 1.5em;
  margin: 0;
}

.App-intro {
  font-size: large;
}

.SampleContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.SampleBox {
  border: 1px solid black;
  width: 20em;
  margin: 2em;
  padding: 10px;
  border-radius: 10px;
}


.SampleHeader {
  font-size: 1.25em;
}

.Button {
  background-color: #00a1f1;
  border: none;
  color: white;
  display: inline-block;
  padding: 15px;
  font-size: 15px;
  cursor: pointer;
}

.Button:disabled {
  background-color: grey;
}

.App-header-span {
  position: absolute;  
  right: 20px;   
}

.Logout-button {   
  color: blue; 
  font-size: 15px;
  cursor: pointer;
}
.user {   
  color: blue; 
  vertical-align: top;
  font-size: 15px;
  cursor: pointer;
}
.nav-top{
  vertical-align: top;
  color:red;

}

.unstyled::-webkit-inner-spin-button,
.unstyled::-webkit-calendar-picker-indicator {
  
    -webkit-appearance: none;
}

body {
  font-size: 11px !important;

}
.form-section .form-group label{
  font-size: 11px !important;
}

.ant-form-item-label > label {
  font-size: 11px !important;
}
.ant-form label {
  font-size: 11px !important;
}
div {
  font-size: 11px !important;
}
.ant-form-item {
  margin-bottom: 9px;
}

.nav-dropdown> a{
  color:yellowgreen!important;
}

.left-form-item{
  margin-bottom: 4px;
}

.nav-item .nav-dropdown> a{
  color:violet!important;
}

.table >table th td  tr {
  border: 1px solid #c8ced3;  
  border-collapse: separate;
  border-spacing: 0;
  overflow-y: auto;
  width: auto;
  padding: 0.20rem !important  ;
  background-color:#fafafa !important;
  
} 

.table-bg th {
  background-color:#fafafa !important;
  border: 1px solid #c8ced3;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  width: auto;
  border-spacing: 0;
  padding: 0.20rem !important  
}

.table-td td {  
  border: 1px solid #c8ced3;
  width: auto;
  border-spacing: 0;
  padding: 0.20rem !important  ;
  
}


.table-from >table th td  tr {
  border: 1px solid #c8ced3;  
  border-collapse: separate;
  border-spacing: 0;
  overflow-y: auto;
  width: auto;
  padding: 0.20rem !important  
  
} 
.table-from-td td {  
  border: 1px solid #c8ced3;
  width: auto;
  border-spacing: 0;
  padding: 0.20rem !important  
}
.table-from-th th {
  background-color:white !important;
  border: 1px solid #c8ced3;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  width: auto;
  border-spacing: 0;
  padding: 0.20rem !important  
}

.table-scroll { 
  overflow-x: 'calc(900px + 50%)';
  overflow-y:350 
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #141516;
}



.pdfWrapper{
  display: flex;   
  overflow-x: auto;
}

.footer{
  display: flex;
  justify-content: space-between;
}

.ant-image-preview-wrap{ 
  top: 55px!important;
  width:70%!important;
  align-content: center!important;
 background-color: #fff;
 margin-left: 200px!important;
 height: 650px!important;
  
}


.sidebar {
  display: flex;
  flex-direction: column;
  padding: sidebar-padding;
  color: sidebar-color;
  background: darkslategrey!important;
}


  .sidebar-close {
    position: absolute;
    right: 0;
    display: none;
    padding: 0 spacer;
    font-size: 24px;
    font-weight: 800;
    line-height: navbar-height;
    color: sidebar-color;
    background: 0;
    border: 0;
    opacity: .8;

    
  }

 .column-money{          
    background-color: darkblue!important; 
    color: white !important;     
  }


  .column-yellow{
    background-color: lightyellow!important; 
  }

  .column-red{
    background-color: rgb(11, 133, 82)!important;
    color: white!important;
  }

  .column-t{
    background-color: lightyellow!important;
    display: flex;
    justify-content: center;
  }
  .ant-modal-body-map>.ant-card-body{
    padding: 0px !important;
    height: 500px;
  }

  .ant-image{
  position: relative;
  display:block !important;
  }

  .ant-empty-normal{
    margin:0px !important;
  }
  
  .dashboard-col{
    padding-right: 1px!important;
    padding-left: 1px!important;
    padding-top: 1px!important;
    padding-bottom: 1px!important;
  }
  .dashboard-row{  
    margin-bottom: -20px !important;
    
  }
  .card-header{
    padding: 0.25rem 1.25rem !important;
  }
  .left-margin{
    margin-left: 5px;
  }

  .img {      
    max-height: 100px;
    max-width: 100%;
    object-fit: contain;
 }